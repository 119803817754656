import _ from "lodash";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "destroy-on-close": "", "title": "Add Shop Order" }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submitModal);
      } } }, [_vm._v("Add")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("text-input", { attrs: { "form-item": "", "rules": "required", "label": "Shop Order Number", "span": 24 }, on: { "change": _vm.onShopOrderNumberChange } }), _c("text-input", { attrs: { "rules": "required", "label": "Item Code", "span": 24, "value": _vm.itemCode, "disabled": true }, on: { "change": function($event) {
      return _vm.storeValue("itemID", $event);
    } } })], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateShopOrder",
  inject: ["resourceProps", "area"],
  data() {
    const [shopOrderProps, departmentsProps] = this.resourceProps;
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      shopOrderProps,
      departmentsProps,
      arrProduct: [],
      arrShopOrderNumber: [],
      visible: false,
      shopOrderID: "",
      itemCode: ""
    };
  },
  computed: {
    areaValue() {
      return this.area;
    },
    listDepartment() {
      return this.departmentsProps.crud.getList();
    },
    countryName() {
      return this.listDepartment.filter((department) => department.id === this.area.value)[0].country;
    }
  },
  async created() {
    if (this.area.value) {
      const res = await this.axios.get(`${this.apiUrl}/line-performance/common/product-masters?areaID=${this.area.value}`);
      this.arrProduct = res.data.results;
      this.storeValue("areaID", this.area.value);
    } else {
      this.arrProduct = [];
    }
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    storeValue(resourceKey, value) {
      this.shopOrderProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    generateLabel(item) {
      return item.description ? `${item.code} - ${item.description}` : `${item.code}`;
    },
    afterModalClose() {
      this.$router.push(`${this.shopOrderProps.redirectRoute}${this.shopOrderID ? "/" + this.shopOrderID : ""}`);
    },
    cancel() {
      this.visible = false;
    },
    async submitModal() {
      await this.shopOrderProps.crud.submitEntity("create").then((response) => {
        this.shopOrderID = response.data.id;
      });
      await this.shopOrderProps.crud.clearEntity("createForm");
      await this.shopOrderProps.crud.fetchList();
      this.visible = false;
    },
    async getArrShopOrderNumber(number) {
      if (number) {
        const { data } = await this.axios.get(`${this.apiUrl}/line-performance/common/shop-order`, {
          params: {
            shopOrderNumber: number,
            countryName: this.countryName
          }
        });
        if (data.shopOrderNumber !== 0) {
          this.storeValue("shopOrderNumber", data.shopOrderNumber);
          this.storeValue("qualityShopOrder", data.qualityShopOrder);
          this.storeValue("itemCode", data.itemCode);
          this.storeValue("dueDate", data.dueDate);
          this.itemCode = data.itemCode;
        } else {
          this.$notification.error({
            message: "Please check to make sure it is correct shop order number"
          });
        }
      }
    },
    onShopOrderNumberChange: _.debounce(function(value) {
      this.getArrShopOrderNumber(value);
    }, 400)
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateShopOrder = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-shop-order" }, [_c("resource", { attrs: { "name": "line-performance.common.shop-order", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": ["line-performance.shop-orders", "identities.common.departments"], "api-url": _vm.apiUrl, "redirect-route": ["/line-performance/shop-orders"] } }, [_c("create-shop-order")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateShopOrder
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
